import _ from 'lodash';

//////////////
// DEV MODE //
//////////////
export const DEV_MODE = false;
export const DEV_TYPE: string = 'alpha';
//export const DEV_TYPE: string = 'dienstleister';

export const NAMING_USER_MEDIA = '_userMedia';
export const NAMING_SUSTAINABILITY_FACTOR = '_sustainability';
export const NAMING_LS_IN_MARKTGEBIET = '_active';

// URLs TO APIs
// export const URL_TO_AUTH_API = 'http://localhost:13000';
// export const URL_TO_REFRESH_TOKEN_AT_AUTH_API =
//   'http://localhost:13000/auth/v1/public/token/refresh';
// export const URL_TO_MEDIA_API = 'http://localhost:13004';
// export const URL_TO_MEDIA_SERVER = 'http://localhost:13007';
// export const URL_TO_KEYVAL_SERVER = 'http://localhost:13009';

//Liveserver
export const URL_TO_AUTH_API = 'https://api.habona.beestate.io/auth';
export const URL_TO_REFRESH_TOKEN_AT_AUTH_API =
  'https://api.habona.beestate.io/auth/v1/public/token/refresh';
export const URL_TO_MEDIA_API = 'https://api.habona.beestate.io/media';
export const URL_TO_MEDIA_SERVER = 'https://media.habona.beestate.io';
export const URL_TO_LIEGENSCHAFTEN_SERVER =
  'https://api.habona.beestate.io/property';
export const URL_TO_KEYVAL_SERVER = 'https://api.habona.beestate.io/keyval';
export const URL_TO_LVEP_SERVER = 'https://api.habona.beestate.io/lvep';
export const URL_TO_DEFAULT_PROPERTY_IMAGE =
  'https://beestate.io/wp-content/uploads/2019/05/beestate_bg_digital.jpg';
export const mediaTokenSecret =
  'aHxLk4WbzwTmBSJ6FUSWZrqRjxSiiYvUmU7V7HHbzO9U07tNTfbznw2Srpaa6Aef842Tkby8p1xiEZ98KtFooJ3i3Y2Aj4t92QnSMLCECgQlYTRDRjFI4htHIg8szruk';
export const OUTPUT_FILE_HEADLINE = 'Habona-Rahmenpreisvergabe';

//DemoServer
// export const URL_TO_AUTH_API =
//   'https://demo-multisourcing-api.serthoro.de/auth';
// export const URL_TO_REFRESH_TOKEN_AT_AUTH_API =
//   'https://demo-multisourcing-api.serthoro.de/auth/v1/public/token/refresh';
// export const URL_TO_MEDIA_API =
//   'https://demo-multisourcing-api.serthoro.de/media';
// export const URL_TO_MEDIA_SERVER =
//   'https://demo-multisourcing-media.serthoro.de';
// export const URL_TO_LIEGENSCHAFTEN_SERVER =
//   'https://demo-multisourcing-api.serthoro.de/property';
// export const URL_TO_KEYVAL_SERVER =
//   'https://demo-multisourcing-api.serthoro.de/keyval';
// export const URL_TO_LVEP_SERVER =
//   'https://demo-multisourcing-api.serthoro.de/lvep';
// export const URL_TO_DEFAULT_PROPERTY_IMAGE =
//   'https://beestate.io/wp-content/uploads/2019/05/beestate_bg_digital.jpg';
// export const mediaTokenSecret =
//   'pgP9dVWMtCV3YtLGZR9NQJjweFNrgfvQv2wTaQJzxr8YqTSqxVLERguTWURe5bEnS6DGrnJkwwjA9DCbQADdgv2bdMzTFAjbIszHwkEtPgyUKsVINEgZfABPmhcavW3A';
// export const OUTPUT_FILE_HEADLINE = 'Beestate-Rahmenpreisvergabe';

//USER RIGHTS
export const USER_RIGHT_DLREAD: string = 'PERMISSION_DLREAD';
export const USER_RIGHT_DLWRITE_KEYVAL: string = 'PERMISSION_DLWRITEKEYVAL';
export const USER_RIGHT_DLWRITE_MEDIA: string = 'PERMISSION_DLWRITEMEDIA';

export const DEFAULT_FACTOR = 1000;
export const DEFAULT_CALCULATION_MULTIPLIER = 1000;
export const DEFAULT_EXPAND_MAX_DEPT_EP = 7;
export const LV_DEPTH_EP_CATALOG: number = 3; //-1 for deactivate
export const VISIBLE_PROVIDER_IN_ANALYSIS = 5;

export const DL_EP_INPUT_DISABLE_FILTER_ID = 'no-filter';

//placeholder & empty stuff
export const EMPTY_FIELD_PLACEHOLDER = ' — ';

//VALIDATE EMAIL REGEX
export const VALIDATE_EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//TOASTS
export const pwChangedToast = 'PwChanged';
export const liegenschaftDeletedToast = 'LiegenschaftDeleted';
export const liegenschaftCreatedToast = 'LiegenschaftCreated';

export const DURATION_NOTIFICATION_SUCCESS_LONG = 4000;
export const DURATION_NOTIFICATION_ERROR_LONG = 4000;

//HEATMAP
export const NUMBER_OF_FACTORS = 5;

export const MIN_VALUE_FACTOR = 0.1;
export const MAX_VALUE_FACTOR = 3;
export const MIN_VALUE_FACTOR_LIEGENSCHAFTEN = 0.7;
export const MAX_VALUE_FACTOR_LIEGENSCHAFTEN = 1.3;
export const MAX_NUMBER_OF_DECIMALS = 3;
export const MIN_NUMBER_OF_DECIMALS = 0;
export const MAX_F =
  _.max([MAX_VALUE_FACTOR, MAX_VALUE_FACTOR_LIEGENSCHAFTEN])! *
  DEFAULT_CALCULATION_MULTIPLIER;

//CALL STATUS
export const SERVER_RESPONSE_PENDING = 'SERVER_RESPONSE_PENDING';
export const SERVER_RESPONSE_ERROR = 'SERVER_RESPONSE_ERROR';
export const SERVER_RESPONSE_SUCCESS = 'SERVER_RESPONSE_SUCCESS';

export const DEFAULT_IMAGE_SIZE = 'MEDIUM';

//ACCEPTED FILE FORMATS
export const ACCEPTED_FORMATS_EXCEL = {
  'application/vnd.ms-excel*': ['.xls', '.xlsx']
};
export const ACCEPTED_FILE_FORMATS = {
  'image/*': ['.jpg', '.jpeg', '.png'],
  'text/*': [
    '.pdf',
    '.json',
    '.xlsx',
    '.xls',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.csv',
    '.txt'
  ]
};

export const LIEGENSCHAFT_SORT_NAME = 'Name';
export const LIEGENSCHAFT_SORT_CITY = 'Stadt';
export const LIEGENSCHAFT_SORT_ADDRESS = 'Straße & Hausnummer';
export const LIEGENSCHAFT_SORT_BUILDING_NO = 'Gebäudenummer';

export const DROPDOWN_LIEGENSCHAFT_SORT = [
  LIEGENSCHAFT_SORT_NAME,
  LIEGENSCHAFT_SORT_CITY,
  LIEGENSCHAFT_SORT_ADDRESS,
  LIEGENSCHAFT_SORT_BUILDING_NO
];

export const ALPHA_DL_NOT_IN_MARKETAREA = 'nicht im Marktgebiet';
export const ALPHA_DL_INCOMPLETE_PRICES = 'unvollständig bepreist';
export const ALPHA_DL_NO_PRICE = 'kein Preis';

export const COUNTRIES: COUNTRY[] = [
  {
    id: 'DE',
    country: 'Deutschland',
    regions: [
      { id: 'BW', region: 'Baden-Württemberg' },
      { id: 'BY', region: 'Bayern' },
      { id: 'BE', region: 'Berlin' },
      { id: 'BB', region: 'Brandenburg' },
      { id: 'HB', region: 'Bremen' },
      { id: 'HH', region: 'Hamburg' },
      { id: 'HE', region: 'Hessen' },
      { id: 'MV', region: 'Mecklenburg-Vorpommern' },
      { id: 'NI', region: 'Niedersachsen' },
      { id: 'NW', region: 'Nordrhein-Westfalen' },
      { id: 'RP', region: 'Rheinland-Pfalz' },
      { id: 'SL', region: 'Saarland' },
      { id: 'SN', region: 'Sachsen' },
      { id: 'ST', region: 'Sachsen-Anhalt' },
      { id: 'SH', region: 'Schleswig-Holstein' },
      { id: 'TH', region: 'Thüringen' }
    ]
  }
];

export const tags = {
  sonstiges: 'Sonstiges',
  igm: 'IGM',
  tgm: 'TGM',
  uegm: 'ÜGM',
  aufzug: 'Aufzugsmanagement',
  energy: 'Energiemanagement',
  waste: 'Entsorgungsmanagement',
  sv: 'SV-Prüfung'
};

export const LeistungsSlices: LEISTUNGSART[] = [
  {
    id: "1",
    icon: "icon-haushaelter",
    title: "Objektleitung",
    tag: ["leitung"],
    sustainability: false,
  },
  {
    id: "2",
    icon: "",
    title: "Übergeordnete Leistungen",
    tag: ["uegm"],
    sustainability: false,
  },
  {
    id: "3",
    icon: "",
    title: "Technischer Betrieb",
    tag: ["tech"],
    sustainability: false,
  },
  {
    id: "4",
    icon: "icon-pruefer",
    title: "Wartung & Inspektion",
    tag: ["wartung"],
    sustainability: false,
  },
  {
    id: "5",
    icon: "icon-gebauedereinigung",
    title: "Gebäudereinigung",
    tag: ["gebauedereinigung"],
    sustainability: false,
  },
  {
    id: "6",
    icon: "icon-aussenanlagen",
    title: "Außenanlagen",
    tag: ["aussenanlagen"],
    sustainability: false,
  },
  {
    id: "7",
    icon: "",
    title: "Grünpflege",
    tag: ["gruen"],
    sustainability: false,
  },
  {
    id: "8",
    icon: "icon-key",
    title: "Sicherheitsdienste",
    tag: ["security"],
    sustainability: false,
  },
  {
    id: "9",
    icon: "icon-schaedlingsbekaempfung",
    title: "Schädlingsbekämpfung",
    tag: ["schaedling"],
    sustainability: false,
  }
];

export type LEISTUNGSART = {
  id: string;
  icon: string;
  title: string;
  tag: string[];
  sustainability: boolean;
};

export type COUNTRY = {
  id: string;
  country: string;
  regions: REGION[];
};

export type REGION = {
  id: string;
  region: string;
};

//CLUSTERVIEW OPTIONAL POSITION
export const prefixInactive = 'inaktivBedarf_';
export const prefixActive = 'aktivBedarf_';
