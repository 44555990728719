import { Link } from 'react-router-dom';
import BeeContentHeadline from '../Atoms/BeeContentHeadline';
import Header from '../Organisms/Header';
import './Datenschutz.scss';

export function Datenschutz() {
  return (
    <>
      <Header />
      <div className="datenschutz">
        <BeeContentHeadline
          label={'Datenschutz'}
          headline={'h1'}
          type={'primary'}
        />
        <div className={'datenschutz-content'}>
          <div className={'paragraph-end'}>
            {`Wir begrüßen Sie bei Ihrem Besuch auf unserer Webseite und freuen uns über Ihr Interesse an unserem Unternehmen. Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. 
        Wir verarbeiten Ihre Daten in Übereinstimmung mit den anwendbaren Rechtsvorschriften zum Schutz personenbezogener Daten, 
        insbesondere der EU-Datenschutz-Grundverordnung (EU-DS-GVO) und den für uns geltenden landesspezifischen Ausführungsgesetzen.`}
          </div>
          <div>
            {`Mithilfe dieser Datenschutzerklärung informieren wir Sie sowohl umfassend über die Verarbeitung Ihrer personenbezogenen
       Daten durch die Serthoro GmbH als auch über die Ihnen zustehenden Rechte. 
       Personenbezogene Daten sind dabei diejenigen Informationen, die eine Identifizierung 
       einer natürlichen Person möglich machen. Dazu gehören insbesondere Name, 
       Geburtsdatum, Adresse, Telefonnummer, E-Mail-Adresse aber auch Ihre IP-Adresse. 
       Anonyme Daten liegen dann vor, wenn keinerlei Personenbezug zum Nutzer hergestellt werden kann.`}
          </div>
          <BeeContentHeadline
            label={'VERANTWORTLICHE STELLE UND DATENSCHUTZBEAUFTRAGTER'}
            headline={'h3'}
            type={'primary'}
          />
          <div className={'bold'}>{`Adresse:`}</div>
          <div>{`Serthoro GmbH`}</div>
          <div>{`Platz der Menschenrechte 1`}</div>
          <div className={'paragraph-end'}>{`96052 Bamberg`}</div>

          <div className={'bold'}>{`Kontaktinformation:`}</div>
          <div>
            <span>Homepage: </span>{' '}
            <a href={'https://www.serthoro.com'}>https://www.serthoro.com</a>
          </div>
          <div>{`Tel: +49 951 91768330`}</div>
          <div className={'paragraph-end'}>
            <span>E-Mail: </span>{' '}
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = 'mailto:info@serthoro.de';
                e.preventDefault();
              }}
            >
              {'info@serthoro.de'}
            </Link>
          </div>

          <div className={'bold'}>{`Kontakt des Datenschutzbeauftragten:`}</div>
          <div>
            <span>E-Mail: </span>{' '}
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = 'mailto:datenschutz@serthoro.de';
                e.preventDefault();
              }}
            >
              {'datenschutz@serthoro.de'}
            </Link>
          </div>
          <BeeContentHeadline
            label={'IHRE RECHTE ALS BETROFFENE PERSON'}
            headline={'h3'}
            type={'primary'}
          />
          <div
            className={'paragraph-end'}
          >{`Zunächst möchten wir Sie an dieser Stelle über Ihre Rechte als betroffene Person informieren. Diese Rechte sind in den Art. 15 – 22 EU-DS-GVO normiert und umfassen:`}</div>
          <div className={'paragraph-end'}>
            <span>
              {`Das Recht auf Auskunft (Art. 15 EU-DS-GVO), Das Recht auf Löschung
            (Art. 17 EU-DS-GVO), Das Recht auf Berichtigung (Art. 16 EU-DS-GVO),
            Das Recht auf Datenübertragbarkeit (Art. 20 EU-DSGVO), Das Recht auf
            Einschränkung der Datenverarbeitung (Art. 18 EU-DS-GVO), Das
            Widerspruchsrecht gegen die Datenverarbeitung (Art. 21 EU-DS-GVO).
            Um diese Rechte geltend zu machen, wenden Sie sich bitte an: `}
            </span>
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = 'mailto:datenschutz@serthoro.de';
                e.preventDefault();
              }}
            >
              {'datenschutz@serthoro.de'}
            </Link>

            <span>{`. Gleiches gilt, wenn Sie Fragen zur Datenverarbeitung in unserem Unternehmen haben. Weiterhin steht Ihnen ein Beschwerderecht an eine Datenschutzaufsichtsbehörde zu.`}</span>
          </div>
          <BeeContentHeadline
            label={'IHRE RECHTE ALS BETROFFENE PERSON'}
            headline={'h3'}
            type={'primary'}
          />
          <div
            className={'paragraph-end'}
          >{`Bitte beachten Sie im Zusammenhang mit Widerspruchsrechten folgendes: Wenn wir Ihre personenbezogenen Daten zum Zwecke der Direktwerbung verarbeiten, haben Sie das Recht, dieser Datenverarbeitung jederzeit ohne Angaben von Gründen zu widersprechen. Dies gilt auch für ein Profiling, soweit es mit der Direktwerbung in Verbindung steht. Wenn Sie der Verarbeitung für Zwecke der Direktwerbung widersprechen, so werden wir Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.`}</div>

          <div className={'paragraph-end'}>
            <span>{`Der Widerspruch ist kostenlos und kann formfrei erfolgen, möglichst an:  
        `}</span>{' '}
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = 'mailto:datenschutz@serthoro.de';
                e.preventDefault();
              }}
            >
              {' datenschutz@serthoro.de'}
            </Link>
            <span>.</span>
          </div>
          <div>{`Für den Fall, dass wir Ihre Daten zur Wahrung berechtigter Interessen verarbeiten, können Sie dieser Verarbeitung aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit widersprechen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wir verarbeiten Ihre personenbezogenen Daten dann nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.`}</div>
          <BeeContentHeadline
            label={'ZWECKE UND RECHTSGRUNDLAGEN DER DATENVERARBEITUNG'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Bei der Verarbeitung Ihrer personenbezogenen Daten werden die Bestimmungen der EU-DS-GVO und alle sonstigen geltenden datenschutzrechtlichen Bestimmungen eingehalten. Rechtsgrundlagen für die Datenverarbeitung ergeben sich insbesondere aus Art. 6 EU-DS-GVO. Wir verwenden Ihre Daten zur Geschäftsanbahnung, zur Erfüllung vertraglicher und gesetzlicher Pflichten, zur Durchführung des Vertragsverhältnisses, zum Anbieten von Produkten und Dienstleistungen sowie zur Stärkung der Kundenbeziehung, was auch Analysen zu Marketingzwecken und Direktwerbung beinhalten kann. Ihre Einwilligung stellt ebenfalls eine datenschutzrechtliche Erlaubnisvorschrift dar. Hierbei klären wir Sie über die Zwecke der Datenverarbeitung und über Ihr Widerrufsrecht auf. Sollte sich die Einwilligung auch auf die Verarbeitung besonderer Kategorien von personenbezogenen Daten beziehen, werden wir Sie in der Einwilligung ausdrücklich darauf hinweisen. Eine Verarbeitung besonderer Kategorien personenbezogener Daten i.S.v. Art. 9 Absatz 1 EU-DS-GVO erfolgt nur dann, wenn dies aufgrund rechtlicher Vorschriften erforderlich ist und kein Grund zu der Annahme besteht, dass Ihr schutzwürdiges Interesse an dem Ausschluss der Verarbeitung überwiegt.`}</div>
          <BeeContentHeadline
            label={'WEITERGABE AN DRITTE'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Wir werden Ihre Daten nur im Rahmen der gesetzlichen Bestimmungen oder bei 
        entsprechender Einwilligung an Dritte weitergeben. Ansonsten erfolgt eine Weitergabe an 
        Dritte nicht, es sei denn wir sind hierzu aufgrund zwingender Rechtsvorschriften dazu 
        verpflichtet (Weitergabe an externe Stellen, wie z.B. Aufsichtsbehörden oder 
          Strafverfolgungsbehörden).`}</div>

          <BeeContentHeadline
            label={'EMPFÄNGER DER DATEN / KATEGORIEN VON EMPFÄNGERN '}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Innerhalb 
          unseres Unternehmens stellen wir sicher, dass nur diejenigen Personen Ihre Daten erhalten, 
          die diese zur Erfüllung der vertraglichen und gesetzlichen Pflichten benötigen. In vielen
           Fällen unterstützen Dienstleister unsere Fachabteilungen bei der Erfüllung ihrer Aufgaben.
            Mit allen Dienstleistern wurde das notwendige datenschutzrechtliche Vertragswerk abgeschlossen. Mit den Dienstleistern 
        wird hier bei Beauftragung eine spezifische Datenschutzvereinbarung vereinbart.`}</div>

          <BeeContentHeadline
            label={'DRITTLANDSÜBERMITTLUNG / DRITTLANDSÜBERMITTLUNGSABSICHT'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Eine Datenübermittlung in Drittstaaten (außerhalb der Europäischen Union bzw. des Europäischen Wirtschaftsraums) findet nur statt, soweit dies zur Durchführung des Schuldverhältnisses erforderlich, gesetzlich vorgeschrieben ist oder Sie uns dazu Ihre Einwilligung erteilt haben. Die Einhaltung des Datenschutzniveaus wird dabei gewährleistet durch unternehmerische Datenschutzvorschriften und den Abschluss des datenschutzrechtlich erforderlichen Vertragswerks.`}</div>

          <BeeContentHeadline
            label={'SPEICHERDAUER DER DATEN'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Wir speichern Ihre Daten, solange diese für den jeweiligen Verarbeitungszweck benötigt werden. Bitte beachten Sie, dass zahlreiche Aufbewahrungsfristen bedingen, dass Daten weiterhin gespeichert werden (müssen). Dies betrifft insbesondere handelsrechtliche oder steuerrechtliche Aufbewahrungspflichten (z.B. Handelsgesetzbuch, Abgabenordnung, etc.). Sofern keine weitergehenden Aufbewahrungspflichten bestehen, werden die Daten nach Zweckerreichung routinemäßig gelöscht. Hinzu kommt, dass wir Daten aufbewahren können, wenn Sie uns hierfür Ihre Erlaubnis erteilt haben oder wenn es zu rechtlichen Auseinandersetzungen kommt und wir Beweismittel im Rahmen gesetzlicher Verjährungsfristen nutzen, die bis zu dreißig Jahre betragen können; die regelmäßige Verjährungsfrist beträgt drei Jahre. `}</div>
          <BeeContentHeadline
            label={'SICHERE ÜBERTRAGUNG DER DATEN​'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Um die bei uns gespeicherten Daten bestmöglich gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder den Zugriff unberechtigter Personen zu schützen, setzen wir entsprechende technische und organisatorische Sicherheitsmaßnahmen ein. Die Sicherheitslevel werden in Zusammenarbeit mit Sicherheitsexperten laufend überprüft und an neue Sicherheitsstandards angepasst. Der Datenaustausch von und zu unserer Webseite findet jeweils verschlüsselt statt. Als Übertragungsprotokoll für betreffende Daten bieten wir für unseren Webauftritt HTTPS an, jeweils unter Verwendung der aktuellen Verschlüsselungsprotokolle. Zudem bieten wir unseren Usern im Rahmen der Kontaktformulare sowie bei Bewerbungen eine Inhaltsverschlüsselung an. Die Entschlüsselung dieser Daten ist nur uns möglich. Außerdem besteht die Möglichkeit, alternative Kommunikationswege zu nutzen (z. B. den Postweg). `}</div>
          <BeeContentHeadline
            label={'VERPFLICHTUNG ZUR BEREITSTELLUNG DER DATEN​​'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`​Diverse personenbezogene Daten sind für die Begründung, Durchführung und Beendigung des Schuldverhältnisses und der Erfüllung der damit verbundenen vertraglichen und gesetzlichen Pflichten notwendig. Gleiches gilt für die Nutzung unserer Webseite und der verschiedenen Funktionen, die diese zur Verfügung stellt. Details dazu haben wir im o.g. Punkt für Sie zusammengefasst. In bestimmten Fällen müssen Daten auch aufgrund gesetzlicher Bestimmungen erhoben bzw. zur Verfügung gestellt werden. Bitte beachten Sie, dass eine Bearbeitung Ihrer Anfrage oder die Durchführung des zugrundeliegenden Schuldverhältnisses ohne Bereitstellung dieser Daten nicht möglich ist.`}</div>

          <BeeContentHeadline
            label={'KATEGORIEN, QUELLEN UND HERKUNFT DER DATEN​​'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Welche Daten wir verarbeiten, bestimmt der jeweilige Kontext: Dies hängt davon ab, ob Sie z.B. eine Anfrage in unser Kontaktformular eingeben. Bitte beachten Sie, dass wir Informationen für besondere Verarbeitungssituationen gegebenenfalls auch gesondert an geeigneter Stelle zur Verfügung stellen, z.B. bei einer Kontaktanfrage.`}</div>

          <BeeContentHeadline
            label={
              'BEIM BESUCH UNSERER WEBSEITE ERHEBEN UND VERARBEITEN WIR FOLGENDE DATEN:'
            }
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Name des Internet Service-Providers Angaben über die Webseite von der aus Sie uns besuchen Verwendeter Webbrowser und verwendetes Betriebssystem Die von Ihrem Internet Service Provider zugewiesene IP-Adresse Angeforderte Dateien, übertragene Datenmenge, Downloads/Dateiexport Angaben über die Webseiten, die Sie bei uns aufrufen inkl. Datum und Uhrzeit Aus Gründen der technischen Sicherheit (insbesondere zur Abwehr von Angriffsversuchen auf unseren Webserver) werden diese Daten gemäß Art. 6 Absatz 1 lit. f EU-DS-GVO gespeichert. Nach spätestens 7 Tagen findet eine Anonymisierung durch Verkürzung der IP-Adresse statt, so dass kein Bezug zum Nutzer hergestellt wird.`}</div>

          <BeeContentHeadline
            label={
              'IM RAHMEN EINER KONTAKTANFRAGE ERHEBEN UND VERARBEITEN WIR FOLGENDE DATEN:'
            }
            headline={'h3'}
            type={'primary'}
          />

          <div>{`Name, Vorname`}</div>
          <div>{`Kontaktdaten`}</div>
          <div>{`Anrede`}</div>
          <div>{`Angaben zu Wünschen und Interessen`}</div>
          <div className={'paragraph-end'}>{`Ihre IP-Adresse`}</div>

          <BeeContentHeadline
            label={
              ' KONTAKTAUFNAHME PER E-MAIL (ART. 6 ABS. 1LIT. A, B, EU-DS-GVO)'
            }
            headline={'h3'}
            type={'primary'}
          />

          <div>{`Sofern Sie sich per E-Mail an uns wenden, werden wir die in der E-Mail mitgeteilten personenbezogenen Daten allein zum Zweck der Bearbeitung Ihrer Anfrage verarbeiten.`}</div>

          <BeeContentHeadline
            label={
              'UMFRAGEN, STUDIEN & WHITEPAPER (ART. 6 ABS. 1 LIT. A EU-DS-GVO)​'
            }
            headline={'h3'}
            type={'primary'}
          />

          <div>
            <span>{`Auf unserer Webseite werden von Zeit zu Zeit verschiedene Umfragen, Studien und Whitepaper angeboten. Für die Durchführung der Umfragen verwenden wir das Tool „LimeSurvey“ des Anbieters LimeSurvey GmbH, Papenreye 63 in 22453 Hamburg, welches auf einem unserer eigenen Server betrieben wird. Die von Ihnen im Zuge der Umfrage bzw. des Download-Vorgangs eingegebenen Daten (Vorname, Nachname, Adresse, Telefonnummer, E-Mail-Adresse sowie Ihre IP-Adresse) werden von uns ausschließlich basierend auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a EU-DS-GVO) verarbeitet. Diese Daten nutzen wir sowohl um Ihnen die angeforderten Dokumente – wie beispielsweise Umfrageergebnisse oder Whitepaper – zukommen zu lassen als auch für interne statistische Auswertungen. Weiterhin werden Sie in einen themenspezifischen Newsletter aufgenommen, der Ihnen regelmäßig ausführliche Informationen zusendet. Selbstverständlich können Sie das Abonnement dieses Newsletters über die vorgesehenen Abmeldemöglichkeiten beenden und somit Ihre Einwilligung – auch in Bezug auf die Newsletter-Analyse – widerrufen. 
        Ihren Widerruf können Sie jederzeit per E-Mail an `}</span>{' '}
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = 'mailto:support@serthoro.de';
                e.preventDefault();
              }}
            >
              {'support@serthoro.de'}
            </Link>
            <span>{` bzw. durch Klick, auf den jedem Newsletter bereitgestellten Link ausüben.`}</span>
          </div>

          <BeeContentHeadline
            label={'CUSTOMER-RELATIONSHIP-MANAGEMENT-SYSTEM „PIPEDRIVE“'}
            headline={'h3'}
            type={'primary'}
          />
          <div>
            <span>{`Bei einer Kontaktaufnahme mit uns (beispielsweise via E-Mail) werden zu deren Bearbeitung und Abwicklung die Angaben des Nutzers gem. Art. 6 Abs. 1 lit. a oder b DS-GVO verarbeitet. 
        Um Ihre Anliegen bestmöglich bearbeiten zu können setzen wir 
        das Customer-Relationship-Management-Tool („CRM-Tool“) Pipedrive ein.
         Der Einsatz des CRM-Systems Pipedrive (des Anbieters Pipedrive OÜ – einer
          Gesellschaft mit beschränkter Haftung aus der Republik Estland) erfolgt auf 
          Grundlage unserer berechtigten Interessen (schnelle und effiziente Bearbeitung 
          von Nutzeranfragen, Bestandskundenmanagement & Neukundengeschäft) gem. Art. 6 Abs.
           1 lit. f DSGVO. Mit dem Anbieter wurde das datenschutzrechtlich erforderliche 
           Vertragswerk abgeschlossen. Die Datenschutzerklärung von Pipedrive können Sie hier abrufen: `}</span>
            <a href={'https://www.pipedrive.com/en/privacy'}>
              https://www.pipedrive.com/en/privacy
            </a>
            <span>{`. Die Löschung ihrer Daten aus unserem CRM-Tool erfolgt,
 wenn wir Ihre Anfrage bearbeitet haben und der Zweck der Speicherung entfallen 
 ist sowie keine anderweitigen entgegenstehenden gesetzlichen Ausnahmen vorliegen. 
 NEWSLETTER (ART. 6 ABS. 1 LIT. A EU-DS-GVO)​Auf unserer Webseite kann ein kostenfreier 
 Newsletter abonniert werden. Die bei der Newsletter-Anmeldung angegebene E-Mail-Adresse 
 sowie Ihr Name werden für den Versand des personalisierten Newsletters verwendet. 
 Hierbei wird der Grundsatz der Datensparsamkeit und Datenvermeidung beachtet, 
 da lediglich die E-Mail-Adresse sowie Ihr Name als Pflichtfeld gekennzeichnet sind. 
 Aus technischer Notwendigkeit sowie zur rechtlichen Absicherung wird bei Bestellung 
 des Newsletters auch Ihre IP-Adresse verarbeitet.`}</span>
          </div>

          <BeeContentHeadline
            label={
              'NEWSLETTERVERSAND ÜBER RAPIDMAIL (ART. 6 ABS. 1 LIT. A DSGVO)'
            }
            headline={'h3'}
            type={'primary'}
          />
          <div
            className={'paragraph-end'}
          >{`Wir nutzen rapidmail für den Versand von Newslettern. Der Anbieter ist die rapidmail GmbH, Wentzingerstraße, 21, 79106 Freiburg, Deutschland. Mit rapidmail wird u.a. der Versand von Newsletternorganisiert und analysiert. Die von Ihnen für den Zweck des Newsletterbezugs eingegeben. Daten werden auf den Servern von rapidmail in Deutschland gespeichert. Näheres entnehmen Sie den Datensicherheitshinweisen von rapidmail. Näheres zu den Analyse-Funktionen von rapidmail erhalten Sie hier.`}</div>
          <div
            className={'paragraph-end'}
          >{`Wenn Sie keine Analyse durch rapidmail möchten, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den Newsletter auch direkt auf der Website abbestellen. Für den Zweck der Analyse enthalten die mit rapidmail versandten E-Mails ein sog. Tracking-Pixel, das sich beim Öffnen der E-Mail mit den Servern von rapidmail verbindet. Auf diese Weise kann festgestellt werden, ob eine Newsletter-Nachricht geöffnet wurde. Des Weiteren können wir mit Hilfe von rapidmail ermitteln, ob und welche Links in der Newsletternachricht angeklickt werden. Bei allen Links in der E-Mail handelt es sich um sogenannte Tracking-Links, mit denen Ihre Klicks gezählt werden können. Je nachdem mit welcher Schriftart der jeweilige Newsletter gestaltet wird, findet eine Verbindung zu externen Servern wie Google Fonts statt.`}</div>
          <div
            className={'paragraph-end'}
          >{`Die Ihrerseits im Rahmen der Einwilligung für den Zweck des Newsletters bei uns gespeicherten Datenwerden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren Servern als auch von den Servern von rapidmail gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.`}</div>

          <BeeContentHeadline
            label={'AUTOMATISIERTE EINZELFALLENTSCHEIDUNG​'}
            headline={'h3'}
            type={'primary'}
          />

          <div
            className={'paragraph-end'}
          >{`Wir nutzen keine rein automatisierten Verarbeitungsprozesse zur Herbeiführung einer Entscheidung. COOKIES (ART. 6 ABS. 1 LIT. F EU-DS-GVO / ART. 6 ABS. 1 LIT. A EU-DS-GVO BEI EINWILLIGUNG)​Unsere Website verwendet an mehreren Stellen sogenannte „Cookies“. Unter einem Cookie versteht man eine kleine Textdatei, die vom Server auf Ihren Rechner übertragen wird und bestimmte Daten wie IP-Adresse, Browser, Betriebssystem, usw. enthalten kann. Cookies starten niemals Programme und übertragen keine Schadsoftware. Sie ermöglichen lediglich eine effizientere Nutzung sowie die Verbesserung unserer Angebote (optionale Cookies) und sind für bestimmte Features unerlässlich (technisch notwendige Cookies). Zudem haben wir durch die Cookies die Möglichkeit, die Effektivität einer bestimmten Anzeige zu messen und ihre Platzierung beispielsweise in Abhängigkeit von den thematischen Nutzerinteressen erfolgen zu lassen.`}</div>
          <div
            className={'paragraph-end'}
          >{`automatisch gelöscht. Permanente Cookies werden automatisch von Ihrem Computer gelöscht, wenn deren Geltungsdauer (im Regelfall sechs Monate) erreicht ist oder Sie diese vor Ablauf der Geltungsdauer selbst löschen.`}</div>
          <div
            className={'paragraph-end'}
          >{`Die Rechtsgrundlage für das Ablegen aller nicht technisch notwendigen Cookies auf ihrem Rechner ist Ihre vorherige Einwilligung gem. Art 6 Abs. 1 S. 1 lit. a DSGVO. Um Ihnen diese Auswahl zu vereinfachen verwenden wir das Consent-Tool „Cookiebot“. Weiterhin haben Sie die Möglichkeit ihren Browser so zu konfigurieren, dass er Sie vor dem Setzen von Cookies informiert oder deren Annahme vollständig verweigert. Auch die Löschung bereits gespeicherter Cookies ist jederzeit möglich. Für die erwähnten Funktionen müssen Sie in Ihrem Browser die entsprechenden Einstellungen anpassen. Informieren Sie sich hierfür über die Hilfsfunktion Ihres entsprechenden Browsers oder über die Website des entsprechenden Herstellers. Wir weisen allerdings darauf hin, dass durch die Deaktivierung von Cookies einige Funktionen dieser Website beeinträchtigt werden können und Ihr Nutzungskomfort eingeschränkt werden kann.`}</div>

          <BeeContentHeadline
            label={
              ' COOKIE-EINWILLIGUNG MIT CONSENT MANAGEMENT (ART. 6 ABS. 1 LIT. F EU-DS-GVO, § 25 ABS. 2 TTDSG)​'
            }
            headline={'h3'}
            type={'primary'}
          />

          <div
            className={'paragraph-end'}
          >{`Wir verwenden auf unserer Website die Cookie-Consent-Plattform CCM19. Hinter CCM19 steht das Unternehmen Papoo Software & Media GmbH, Auguststr. 4, 53229 Bonn (DE). Mit dem Anbieter wurde das datenschutzrechtlich vorgeschriebenene Vertragswerk abgeschlossen, um zu gewährleisten, dass die Papoo Software & Media GmbH die personenbezogenen Daten unserer Websitebesucher unter Einhaltung der der DSGVO verarbeitet. CCM19 bietet uns unter anderem die Möglichkeit, Ihnen einen umfangreichen Cookie-Hinweis (auch Cookie-Banner oder Cookie-Notice genannt) zu liefern. Durch die Verwendung dieser Funktion können Daten von Ihnen an CCM19 gesendet, gespeichert und verarbeitet werden.`}</div>
          <div
            className={'paragraph-end'}
          >{`Datenschutz nehmen wir sehr ernst. Wir wollen Ihnen ganz genau zeigen, was auf unserer Website vor sich geht und welche Daten von Ihnen gespeichert werden. CCM19 hilft uns einen guten Überblick über all unserer Cookies (Erst- und Drittanbieter-Cookies) zu erhalten. So können wir Sie über die Nutzung von Cookies auf unserer Website exakt und transparent informieren. Sie bekommen stets einen aktuellen und datenschutzkonformen Cookie-Hinweis und entscheiden selbst, welche Cookies Sie zulassen und welche nicht.`}</div>
          <div
            className={'paragraph-end'}
          >{`Wenn Sie Cookies zulassen, werden folgende Daten an CCM19 übertragen, gespeichert und verarbeitet.`}</div>

          <div>{`IP-Adresse (in anonymisierter Form)`}</div>
          <div>{`Datum und Uhrzeit Ihres Einverständnisses`}</div>
          <div>{`unsere Website-URL`}</div>
          <div>{`technische Browserdaten`}</div>
          <div>{`verschlüsselter, anonymer Key`}</div>
          <div
            className={'paragraph-end'}
          >{`die Cookies, die Sie zugelassen haben (als Zustimmungsnachweis)`}</div>
          <div
            className={'paragraph-end'}
          >{`Die Papoo Software & Media GmbH speichert in Ihrem Browser ein technisch notwendiges Cookie, um die von Ihnen erteilten Einwilligungen oder den Widerruf zuzuordnen. Diese Daten werden solange gespeichert, bis Sie entweder das CCM19 Cookie aus Ihrem Browser löschen, oder der Zweck des Cookies erlischt oder Sie uns zur Löschung auffordern. Gesetzliche Aufbewahrungspflichten bleiben hiervon unberührt.`}</div>
          <div
            className={'paragraph-end'}
          >{`Alle erhobenen Daten werden ausschließlich innerhalb der Europäischen Union übertragen, gespeichert und weitergeleitet. Alle User Daten werden von CCM19 nach 12 Monaten ab der Registrierung (Cookie-Zustimmung) bzw. unmittelbar nach Kündigung des Services gelöscht.`}</div>
          <div>{`Wenn Sie mehr über die Datenschutzrichtlinien von „CCM19“ bzw. dem dahinterstehenden Unternehmen erfahren wollen, empfehlen wir Ihnen die Datenschutzrichtlinien des Anbieters durchzulesen.`}</div>

          <BeeContentHeadline
            label={'SOCIAL PLUGINS SOZIALER NETZWERKE​'}
            headline={'h3'}
            type={'primary'}
          />

          <div>{`Es werden zurzeit keine Social Plugins verwendet.`}</div>

          <BeeContentHeadline
            label={'ONLINEANGEBOT BEI KINDERN​'}
            headline={'h3'}
            type={'primary'}
          />

          <div>{`Personen unter 16 Jahren dürfen ohne Zustimmung der Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln bzw. eine Einwilligungserklärung abgeben. Wir möchten Eltern und Erziehungsberechtigte dazu auffordern, an den Online-Aktivitäten und -Interessen ihrer Kinder aktiv teilzunehmen.`}</div>

          <BeeContentHeadline
            label={' LINKS ZU ANDEREN ANBIETERN​​'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Unsere Webseite enthält – deutlich erkennbar – auch Links auf die Internet-Auftritte anderer Unternehmen. Soweit Links zu Webseiten anderer Anbieter vorhanden sind, haben wir auf deren Inhalte keinen Einfluss. Daher kann für diese Inhalte auch keine Gewähr und Haftung übernommen werden. Für die Inhalte dieser Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße und erkennbare Rechtsverletzungen geprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend entfernt.`}</div>
        </div>
      </div>
    </>
  );
}

export default Datenschutz;
